import { BehaviorSubject } from "rxjs";
import { IGNORE } from "../config/ignore.config";
import { ApplicationService } from "../services/application.service";

interface History {
  path: string;
  name: string;
}

export class HistoryHandler {
  public application: ApplicationService;

  public stack: History[];
  public position: BehaviorSubject<number>;
  private skip: boolean;

  public hasPrevious: boolean;
  public hasNext: boolean;

  public constructor(application: ApplicationService) {
    this.application = application;
    this.stack = [];
    this.position = new BehaviorSubject(0);
    this.skip = false;
    this.hasPrevious = false;
    this.hasNext = false;

    this.application.title.subscribe((name) => {
      if (name) {
        const currentUrl = this.application.router.url;

        if (!IGNORE.ROUTES.includes(currentUrl) && !this.skip) {
          this.stack = this.stack
            .slice(this.position.value)
            .filter((item) => item !== undefined) // cut off more recent paths when user is not on most recent path
            .filter((item) => item.path !== currentUrl);
          this.stack.unshift({
            path: currentUrl,
            name: name,
          });
          this.position.next(0);
        }

        this.skip = false;
        this.evaluateArrows();
      }
    });
  }

  /**
   * Go forward one page
   */
  public next(): void {
    try {
      this.position.next(this.position.value - 1);
      this.navigate(this.position.value);
    } catch (e) {
      this.position.next(this.position.value + 1);
    }
    this.evaluateArrows();
  }

  /**
   * Go back one page
   */
  public previous(): void {
    try {
      this.position.next(this.position.value + 1);
      this.navigate(this.position.value);
    } catch (e) {
      this.position.next(this.position.value - 1);
    }
    this.evaluateArrows();
  }

  /**
   * Go back to the last page
   */
  public reset(): void {
    this.position.next(0);
    this.evaluateArrows();
  }

  /**
   * Navigate the stack and skip registration
   * @param position
   */
  public navigate(position: number): void {
    const path = this.stack[position].path;
    if (this.application.router.url != path) {
      this.skip = true;
      this.application.refresh(path);
    } else {
      throw new Error("Cannot navigate to the same path.");
    }
    this.evaluateArrows();
  }

  /**
   * Navigate the stack by Object
   * @param item
   */
  public navigateByPath(item: History): void {
    const index = this.stack.findIndex((compareItem) => compareItem === item);
    this.stack = this.stack.slice(index).filter((stackItem) => stackItem !== undefined);
    this.position.next(0);
    this.skip = true;
    this.application.refresh(item.path);
    this.evaluateArrows();
  }

  /**
   * Reset history tracking
   */
  public clear(): void {
    this.stack = [];
    this.position.next(0);
    this.evaluateArrows();
  }

  /**
   * Check wether arrows for next and previous should still be shown
   */
  public evaluateArrows(): void {
    this.hasNext = this.position.value !== 0;
    this.hasPrevious = this.stack.length > 0 && this.position.value !== this.stack.length - 1;
  }
}
