@if (!disabled && control) {
  <div class="date-container">
    <div class="fake-input">
      <div class="inputs">
        <input
          type="text"
          #idate
          (keyup)="onKeyUp($event, 'date')"
          (beforeinput)="onBeforeInput($event)"
          (blur)="onInputBlur('date')"
          [formControl]="group.controls.date"
          maxlength="2"
          name=""
          id=""
        />
        <span class="seperator"> - </span>
        <input
          type="text"
          #imonth
          (keyup)="onKeyUp($event, 'month')"
          (beforeinput)="onBeforeInput($event)"
          (blur)="onInputBlur('month')"
          [formControl]="group.controls.month"
          maxlength="2"
          name=""
          id=""
        />
        <span class="seperator"> - </span>
        <input
          type="text"
          #iyear
          (keyup)="onKeyUp($event, 'year')"
          (beforeinput)="onBeforeInput($event)"
          (blur)="onInputBlur('year')"
          [formControl]="group.controls.year"
          maxlength="4"
          name=""
          id=""
        />
      </div>
      <button cdkOverlayOrigin #trigger="cdkOverlayOrigin" type="button" (click)="pickerOpen = !pickerOpen">
        <mat-icon>calendar_month</mat-icon>
      </button>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="pickerOpen"
      (detach)="pickerOpen = false"
      (overlayOutsideClick)="pickerOpen = false"
      [cdkConnectedOverlayScrollStrategy]="scroll"
    >
      <div class="date-picker-overlay">
        <mat-calendar [autofocus]="true" [selected]="selected$ | async" [startAt]="selected$ | async" (selectedChange)="onDateSelected($event)" #datepicker />
      </div>
    </ng-template>
  </div>
} @else {
  <app-template-html [value]="value" [control]="control" />
}
