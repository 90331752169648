import { EnvironmentHelper } from "src/classes/EnvironmentHelper";
import { OrganisationConfig } from "src/interfaces/environment";

const CONFIG: OrganisationConfig = {
  NAME: "dbl",
  TITLE: "Mijn Groene Knip",
  API: null,
  WEBSOCKET: null,
  SHOW_HISTORY: true,
  QR_LOGIN: true,
  QR_REFRESH_RATE: 60000,
  QR_CHECK_RATE: 5000,
  MSAL: null,
  SUBFUNCTIONALITIES: [],
  MAP: null,
};

const CONFIG_DEVELOPMENT: Partial<OrganisationConfig> = {
  TITLE: "Mijn Groene Knip | Development",
  API: "https://dev.api.mijngroeneknip.nl",
};

const CONFIG_TEST: Partial<OrganisationConfig> = {
  TITLE: "Mijn Groene Knip | Test",
  API: "https://test.api.mijngroeneknip.nl",
};

const CONFIG_STAGING: Partial<OrganisationConfig> = {
  TITLE: "Mijn Groene Knip | Staging",
  API: "https://staging.api.mijngroeneknip.nl",
};

const CONFIG_PRODUCTION: Partial<OrganisationConfig> = {
  TITLE: "Mijn Groene Knip",
  API: "https://api.mijngroeneknip.nl",
};

export const ORGANISATION = EnvironmentHelper.GetEnvironment({
  CONFIG,
  CONFIG_TEST,
  CONFIG_DEVELOPMENT,
  CONFIG_STAGING,
  CONFIG_PRODUCTION,
});
