import { Injectable } from "@angular/core";
import { v4 as uuidv4 } from "uuid";
import { Key } from "../enums/key";

export interface KeyListenerOptions {
  element: HTMLElement;
}

export interface KeyListener {
  id: string;
  sequence: KeySequence;
  options: KeyListenerOptions;
  callback: () => void;
}

export type KeySequence = Key[];

@Injectable({
  providedIn: "root",
})
export class KeylistenerService {
  public readonly elements: Set<HTMLElement>;
  public readonly listeners: Map<string, KeyListener>;
  public readonly options: KeyListenerOptions;

  public constructor() {
    this.elements = new Set();
    this.listeners = new Map();

    this.options = {
      element: document.body,
    };
  }

  public add(sequence: KeySequence | Key, callback: () => void, listenerOptions: Partial<KeyListenerOptions> = {}): string {
    const options = {
      ...this.options,
      ...listenerOptions,
    };
    const element = options.element;
    const id = uuidv4();

    if (!this.elements.has(element)) {
      element.addEventListener("keydown", (e) => this.onKeydown(e));
      this.elements.add(element);
    }

    /**
     * Todo check in execute if correct element is focused
     */

    this.listeners.set(id, {
      id,
      callback,
      sequence: Array.isArray(sequence) ? sequence : [sequence],
      options,
    });

    return id;
  }

  public remove(id: string): void {
    try {
      const listener = this.getListener(id);
      const element = listener.options.element;

      if (!this.getListenersByElement(element).length) this.elements.delete(element);
      this.listeners.delete(id);
    } catch (err) {
      console.error("Unable to remove keylistener => ", err);
    }
  }

  public getListenersByElement(element: HTMLElement): KeyListener[] {
    return Array.from(this.listeners.values()).filter((listener) => listener.options.element === element) || <KeyListener[]>[];
  }

  public getListener(id: string): KeyListener {
    const listener = this.listeners.get(id);
    if (listener) {
      return listener;
    } else {
      throw new Error("keyListener does not exists.");
    }
  }

  private onKeydown(event: KeyboardEvent): void {
    const listeners = Array.from(this.listeners.values());
    const correct = listeners.filter((listener) => {
      const { sequence } = listener;
      return sequence.every((key) => {
        if (key === Key.SHIFT) return event.shiftKey;
        if (key === Key.ALT) return event.altKey;
        if (key === Key.CONTROL) return event.ctrlKey;
        if (key === Key.META) return event.metaKey;
        return key === event.code;
      });
    });

    for (const i of correct) {
      event.preventDefault();
      i.callback();
    }
  }
}
